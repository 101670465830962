import { Button, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import isEmail from 'validator/lib/isEmail';
import ScrollReveal from 'scrollreveal';
import './Professionals.css';
import { getDB } from "../../services/FirebaseService";
import { addDoc, collection } from "@firebase/firestore";

function Professionals() {
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const theme = useTheme();

    useEffect(() => {
        ScrollReveal().reveal('.professionals', {
            duration: 2000,
            distance: '50%'
        });
    }, []);

    async function submitForm() {
        if (isEmail(email)) {
            setEmailError(false);
            await addDoc(collection(getDB(), "webapp"), {
                name: name,
                phone: phone,
                email: email
            });
        }
        else {
            setEmailError(true);
        }
        setSnackBarOpen(true);
    }

    function handleNameChange(e: any) {
        setName(e.target.value);
    }

    function handlePhoneChange(value: string) {
        setPhone(value);
    }

    function handleEmailChange(e: any) {
        setEmail(e.target.value);
    }

    function isButtonEnabled(): boolean {
        return name.length > 0 && (
            isPhoneValid() ||
            email.length > 0
        );
    }

    function isPhoneValid() {
        return phone.length > 0 && matchIsValidTel(phone);
    }

    return (
        <>
            <div className='professionals'>
                <Typography sx={{ fontSize: '2em', marginBottom: 2 }}>
                    We offer a range of services to help practitioners <br /> get started with <span style={{ color: theme.palette.primary.main, fontSize: '1em' }}>ecodontics.</span>
                </Typography>
                <Typography sx={{ marginBottom: 2 }}>Enter your details below and a representative will get in touch with you soon!</Typography>

                <div className='professionals-inner'>
                    <TextField variant='outlined' onChange={handleNameChange} label='Name' type='text' color='primary' />
                    <MuiTelInput defaultCountry='GB' preferredCountries={['ES']} value={phone} onChange={handlePhoneChange} />
                    <TextField error={emailError} variant='outlined' onChange={handleEmailChange} label='Email' type='email' color='primary' />
                    <Button disabled={!isButtonEnabled()} onClick={submitForm} variant='contained' sx={{ textTransform: 'none' }}>
                        <span style={{ color: theme.palette.secondary.main }}>Submit</span>
                    </Button>
                </div>
            </div>
            <Snackbar
                open={snackBarOpen}
                onClose={() => setSnackBarOpen(false)}
                autoHideDuration={2000}
                message={emailError ? 'Invalid email address' : 'Details received. Thank you!'}
            />
        </>
    )
}

export default Professionals;