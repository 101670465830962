import React from 'react';
import './App.css';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Professionals from './components/Professionals/Professionals';
import Home from './components/Home/Home';
import Terms from './components/Terms/Terms';
import Privacy from './components/Privacy/Privacy';


function App() {
  return (
    <BrowserRouter>
      <div className='App' >
        <NavBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/professionals' element={<Professionals />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
