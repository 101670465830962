import React, { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Chart } from "chart.js";
import stickman from '../../assets/stickman.svg';
import './Doughnut.css';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Context from '@mui/base/TabsUnstyled/TabsContext';
import { isMobile } from 'react-device-detect';

function DoughnutChart() {
    const [tooltipTitle, setTooltipTitle] = useState<string | null>(null);
    const chartRef = useRef(null);
    const chartEntry = useIntersectionObserver(chartRef, { threshold: 0.4 });
    ChartJS.register(ArcElement, Tooltip, Legend);

    // const title = tooltip.body[0].lines[0].split(':')[0];

    const getOrCreateTooltip = (chart: any) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.color = 'white';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .1s ease';

            const table = document.createElement('table');
            table.style.margin = '0px';

            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    const externalTooltipHandler = (context: any) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map((b: any) => b.lines);

            const tableHead = document.createElement('thead');

            titleLines.forEach((title: any) => {
                const tr = document.createElement('tr');

                const th = document.createElement('th');
                const text = document.createTextNode(title);

                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
            });

            const tableBody = document.createElement('tbody');
            bodyLines.forEach((body: any, i: any) => {
                const colors = tooltip.labelColors[i];

                const span = document.createElement('span');
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = '2px';
                span.style.marginRight = '10px';
                span.style.height = '10px';
                span.style.width = '10px';
                span.style.display = 'inline-block';

                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';

                const td = document.createElement('td');

                console.log(body)

                const text = document.createTextNode(body[0].split(':')[0]);

                td.appendChild(span);
                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
            });

            const tableRoot = tooltipEl.querySelector('table');

            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    };

    return (
        <>
            <div ref={chartRef} className='chart-stack'>
                <img src={stickman} width={isMobile ? 300 : 500} />
                {/* chart visible after 50% is in viewport */}
                {chartEntry?.isIntersecting &&
                    <Doughnut
                        options={{
                            plugins: {
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: false,
                                    external: externalTooltipHandler
                                }
                            }
                        }}
                        data={{
                            labels: [
                                'Orthodontist',
                                'Dental beautician',
                                'Speech therapist',
                                'Osteopath',
                                'Nutritionist'
                            ],
                            datasets: [{
                                label: 'My First Dataset',
                                data: [20, 20, 20, 20, 20],
                                backgroundColor: [
                                    '#00506D',
                                    '#C5F8FF',
                                    '#00A6E3',
                                    '#4B7E93',
                                    '#45BCE8',

                                ],
                                hoverOffset: 4
                            }]
                        }} />
                }
            </div>
        </>
    )
}

export default DoughnutChart;