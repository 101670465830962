import { useTheme } from '@mui/material';
import { Button, Divider, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';

function Footer() {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <>
            <div className='footer'>
                <Typography>Copyright © 2022 Ecodontics Ltd.</Typography>
                <div className='footer-buttons'>
                    <Button onClick={() => navigate('/terms')} variant='contained' color='primary' sx={{ textTransform: 'none', margin: 1 }}>
                        <span style={{ color: theme.palette.secondary.main }}>Terms and Conditions</span>
                    </Button>
                    <Button onClick={() => navigate('/privacy')} variant='contained' color='primary' sx={{ textTransform: 'none', margin: 1}}>
                        <span style={{ color: theme.palette.secondary.main }}>Privacy Policy</span>
                    </Button>
                </div>
            </div>
        </>
    );
}

export default Footer;