import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import InfoCard from '../components/InfoCard/InfoCard';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import anime from 'animejs';
import { useScrollDirection } from 'react-use-scroll-direction'
import './CardsSection.css';
import { isMobile } from 'react-device-detect';

function CardsSection() {
    const [hasDentalOutPlayed, setHasDentalOutPlayed] = useState<boolean>(false);
    const [hasCorporalOutPlayed, setHasCorporalOutPlayed] = useState<boolean>(false);
    const [currentState, setCurrentState] = useState<number>(0);
    const dentalRef = useRef(null);
    const corporalRef = useRef(null);
    const mentalRef = useRef(null);
    const corporalEntry = useIntersectionObserver(corporalRef, {});
    const mentalEntry = useIntersectionObserver(mentalRef, {});
    const { isScrollingDown, scrollDirection } = useScrollDirection();
    const animationOutDental = anime({
        targets: '.health-type-dental',
        translateY: [0, -100],
        duration: 1500,
        opacity: [1, 0],
        autoplay: false
    });
    const animationOutCorporal = anime({
        targets: '.health-type-corporal',
        translateY: [0, -100],
        duration: 1500,
        opacity: [1, 0],
        autoplay: false
    });
    const TITLE_FONT_SIZE = isMobile ? '2em' : '4em';
    const TITLE_WIDTH = isMobile ? 0 : 300;
    const CARD_FONT_SIZE = isMobile ? '1.5em' : '2em';


    return (
        <>
            <div className='section health'>
                <div style={{ position: 'sticky', top: 0, zIndex: -1 }}>
                    <Typography color='primary' sx={{
                        fontSize: TITLE_FONT_SIZE,
                        position: 'absolute',
                        left: isMobile ? 180 : 300,
                        height: 'inherit',
                    }}>health.</Typography>
                </div>
                <div ref={dentalRef} className='section health'>
                    <div className='health-title'>
                        <Typography
                            className='health-type-dental'
                            sx={{
                                fontSize: TITLE_FONT_SIZE,
                                width: TITLE_WIDTH,
                            }}>
                            Dental
                        </Typography>
                        &nbsp;
                    </div>
                    <div className='health-cards'>
                        <div className='health-cards-1'>
                            <InfoCard width={isMobile ? 150 : 200} height={isMobile ? 100 : 200}>
                                <span style={{ fontSize: CARD_FONT_SIZE }}>Aligns uneven teeth.</span>
                            </InfoCard>
                            <InfoCard width={isMobile ? 150 : 200} height={isMobile ? '100%' : 200}>
                                <span style={{ fontSize: CARD_FONT_SIZE }}>Fixes irregular smiles.</span>
                            </InfoCard>
                        </div>
                        <div className='health-cards-2'>
                            <InfoCard width={isMobile ? 150 : 200} height={isMobile ? '100%' : 500}>
                                <span style={{ fontSize: CARD_FONT_SIZE }}>Protects your gums.</span>
                            </InfoCard>
                        </div>
                        <div className='health-cards-3'>
                            <InfoCard height={isMobile ? 50 : 100} >
                                <span style={{ fontSize: CARD_FONT_SIZE }}>Targets malocclusions.</span>
                            </InfoCard>
                            <div className='health-cards-3-inner'>
                                <div className='health-cards-3-inner1'>
                                    <InfoCard width={isMobile ? 200 : 400} height={isMobile ? 100 : 200}>
                                        <span style={{ fontSize: CARD_FONT_SIZE }}>Corrects abnormal face growth.</span>
                                    </InfoCard>
                                    <InfoCard width={isMobile ? 200 : 400} height={isMobile ? 100 : 200}>
                                        <span style={{ fontSize: CARD_FONT_SIZE }}>Prevents teeth grinding (bruxism).</span>
                                    </InfoCard>
                                </div>
                                <InfoCard width={'100%'}>
                                    <span style={{ fontSize: CARD_FONT_SIZE }}>Limits temporomandibular disorder (TMD).</span>
                                </InfoCard>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={corporalRef} className='section health'>
                    <div className='health-title' >
                        <Typography
                            className='health-type-corporal'
                            sx={{ fontSize: TITLE_FONT_SIZE, width: TITLE_WIDTH }}>
                            Corporal
                        </Typography>
                    </div>
                    <div className='health-cards'>
                        <div className='health-cards-1'>
                            <InfoCard width={isMobile ? 200 : 200} height={isMobile ? 100 : 200}>
                                <span style={{ fontSize: CARD_FONT_SIZE }}>Corrects sleep apnea.</span>
                            </InfoCard>
                            <InfoCard width={isMobile ? 200 : 200} height={isMobile ? '100%' : 200}>
                                <span style={{ fontSize: CARD_FONT_SIZE }}>Helps with normal deglutition.</span>
                            </InfoCard>
                        </div>
                        <div className='health-cards-2'>
                            <InfoCard width={isMobile ? 150 : 200} height={isMobile ? '100%' : 500}>
                                <span style={{ fontSize: CARD_FONT_SIZE }}>Limits bedwetting (enuresis).</span>
                            </InfoCard>
                        </div>
                        <div className='health-cards-3'>
                            <InfoCard height={isMobile ? 50 : 100}>
                                <span style={{ fontSize: CARD_FONT_SIZE }}>Balanced and harmoneous child development.</span>
                            </InfoCard>
                            <div className='health-cards-3-inner'>
                                <div className='health-cards-3-inner1'>
                                    <InfoCard width={isMobile ? 200 : 400} height={isMobile ? 100 : 200}>
                                        <span style={{ fontSize: CARD_FONT_SIZE }}>Improves balance.</span>
                                    </InfoCard>
                                    <InfoCard width={isMobile ? 200 : 400} height={isMobile ? 100 : 200}>
                                        <span style={{ fontSize: CARD_FONT_SIZE }}>Protects against fibromyalgia.</span>
                                    </InfoCard>
                                </div>
                                <InfoCard width={'100%'}>
                                    <span style={{ fontSize: CARD_FONT_SIZE }}>Neutralises mouth breathing.</span>
                                </InfoCard>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={mentalRef} className='health'>
                    <div className='health-title' >
                        <Typography
                            className='health-type'
                            sx={{ fontSize: TITLE_FONT_SIZE, width: TITLE_WIDTH }}>
                            Mental
                        </Typography>
                    </div>
                </div>
            </div>
            <div className='health-cards'>
                <div className='health-cards-1'>
                    <InfoCard width={isMobile ? 150 : 200} height={isMobile ? 100 : 200}>
                        <span style={{ fontSize: CARD_FONT_SIZE }}>Smile with courage.</span>
                    </InfoCard>
                    <InfoCard width={isMobile ? 150 : 200} height={isMobile ? '100%' : 200}>
                        <span style={{ fontSize: CARD_FONT_SIZE }}>Improve your quality of life.</span>
                    </InfoCard>
                </div>
                <div className='health-cards-2'>
                    <InfoCard width={isMobile ? 150 : 200} height={isMobile ? '100%' : 500}>
                        <span style={{ fontSize: CARD_FONT_SIZE }}>Energize.</span>
                    </InfoCard>
                </div>
                <div className='health-cards-3'>
                    <InfoCard height={isMobile ? 50 : 100}>
                        <span style={{ fontSize: CARD_FONT_SIZE }}>Avoid aggressive and bloody treatments.</span>
                    </InfoCard>
                    <div className='health-cards-3-inner'>
                        <div className='health-cards-3-inner1'>
                            <InfoCard width={isMobile ? 200 : 400} height={isMobile ? 100 : 200}>
                                <span style={{ fontSize: CARD_FONT_SIZE }}>Feel good.</span>
                            </InfoCard>
                            <InfoCard width={isMobile ? 200 : 400} height={isMobile ? 100 : 200}>
                                <span style={{ fontSize: CARD_FONT_SIZE }}>Gain confidence.</span>
                            </InfoCard>
                        </div>
                        <InfoCard width={'100%'}>
                            <span style={{ fontSize: CARD_FONT_SIZE }}>Boost your professional and educational performance.</span>
                        </InfoCard>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardsSection;