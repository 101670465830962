import React from "react";
import { useNavigate } from 'react-router-dom';
import './Logo.css';

function Logo() {
    const navigate = useNavigate();

    function navigateHome() {
        navigate('/');
    }

    return (
        <div className="logo" onClick={navigateHome}>
            <span className="title">ecodontics</span>
        </div>
    )
};

export default Logo;