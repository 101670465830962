import { createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6BB53A'
        },
        secondary: {
            main: '#ffffff'
        }
    },
    typography: {
        body1: {
            letterSpacing: 5,
            fontWeight: 500
        }
    }
});

export default theme;