import { getApps, initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

function getDB() {
    const firebaseConfig = {
        apiKey: "AIzaSyD8Hiy9w2FXw6cFHvxRjt4lH74p6jE5_rA",
        authDomain: "ecodontics-f218f.firebaseapp.com",
        projectId: "ecodontics-f218f",
        storageBucket: "ecodontics-f218f.appspot.com",
        messagingSenderId: "483615927763",
        appId: "1:483615927763:web:5596e618b75c2ed0029326",
        measurementId: "G-JL1HE2CR7E"
    };
    if (getApps().length === 0) {
        initializeApp(firebaseConfig)
    }
    return getFirestore();
};

export { getDB };