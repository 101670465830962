import React, { useState } from 'react';
import Logo from '../Logo/Logo';
import CallIcon from '@mui/icons-material/Call';
import './NavBar.css';
import { isMobile } from 'react-device-detect';
import { Button, SwipeableDrawer, useTheme } from '@mui/material';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import { Link, useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { call } from '../../services/ContactService';

function NavBar() {
   const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
   const theme = useTheme();
   const navigate = useNavigate();

   function handleProAreaClick(_: any) {
      navigate('/professionals');
      if (isMobile) {
         toggleDrawer();
      }
   }

   function toggleDrawer() {
      setDrawerOpen(!isDrawerOpen);
   }

   function drawer() {
      return (
         <List>
            <ListItem key='Professionals area' disablePadding>
               <ListItemButton onClick={handleProAreaClick}>
                  <ListItemIcon>
                     <WorkRoundedIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText primary='Professionals area' />
               </ListItemButton>
            </ListItem>
         </List>
      )
   }

   return (
      <div className='navBar'>
         <div className='navBar-inner'>
            <Logo />
            <div className='navBar-right'>
               {!isMobile &&
                  <div className='phone' onClick={call}>
                     <CallIcon color='primary' sx={{ paddingRight: 2 }} />
                     <span>+34 615 503 852</span>
                  </div>
               }
               {isMobile
                  ?
                  <>
                     <Button onClick={toggleDrawer} variant='text'>
                        <MenuRoundedIcon color='primary' />
                     </Button>
                     <SwipeableDrawer anchor='right' open={isDrawerOpen} onOpen={toggleDrawer} onClose={toggleDrawer}>
                        {drawer()}
                     </SwipeableDrawer>
                  </>
                  :
                  <Button onClick={handleProAreaClick} size='large' variant='contained' color='primary' sx={{ textTransform: 'none', marginLeft: 5 }}>
                     <WorkRoundedIcon color='secondary' sx={{ paddingRight: 2 }} />
                     <span style={{ color: theme.palette.secondary.main }}>Professionals area</span>
                  </Button>
               }
            </div>
         </div>
      </div>
   )
};

export default NavBar;