import { Button, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import CallIcon from '@mui/icons-material/Call';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import CardsSection from '../../sections/CardsSection';
import { isMobile } from 'react-device-detect';
import DoughnutChart from '../Doughnut/Doughnut';
import ScrollReveal from 'scrollreveal';
import './Home.css';
import { call, email } from '../../services/ContactService';

function Home() {
    const theme = useTheme();
    const TITLE_FONT_SIZE = isMobile ? '2em' : '4em';

    useEffect(() => {
        ScrollReveal().reveal('.front-text', {
            duration: 2000,
            distance: '50%'
        });
    }, []);

    return (
        <div className="App-header">
            <div className='front-text'>
                <span>Invisible.</span>
                <span>Natural.</span>
                <span>With your <span style={{ color: '#6BB53A' }}>wellbeing</span> in mind.</span>
            </div>
            <div className='section front-buttons'>
                <Button onClick={call} size='large' variant='contained' color='primary' sx={{ textTransform: 'none' }}>
                    <CallIcon color='secondary' sx={{ paddingRight: 2 }} />
                    <span style={{ color: theme.palette.secondary.main }}>Call us</span>
                </Button>
                <Button onClick={email} size='large' variant='contained' color='primary' sx={{ textTransform: 'none' }}>
                    <EmailRoundedIcon color='secondary' sx={{ paddingRight: 2 }} />
                    <span style={{ color: theme.palette.secondary.main }}>Write us</span>
                </Button>
            </div>
            <CardsSection />
            <div className='section holistic'>
                {isMobile
                    ?
                    <>
                        <Typography sx={{ fontSize: TITLE_FONT_SIZE }}>Holistic approach to transforming your smile.</Typography>
                    </>
                    :
                    <>
                        <Typography sx={{ fontSize: TITLE_FONT_SIZE }}>Holistic approach to</Typography>
                        <Typography sx={{ fontSize: TITLE_FONT_SIZE }}>transforming your</Typography>
                        <Typography sx={{ fontSize: TITLE_FONT_SIZE }}>smile.</Typography>
                    </>
                }
                <div className='holistic-chart'>
                    <DoughnutChart />
                </div>
            </div>
            <div className='section finance'>
                <Typography sx={{ fontSize: TITLE_FONT_SIZE }}>We help you invest in </Typography>
                <Typography sx={{ fontSize: TITLE_FONT_SIZE }}>yourself.</Typography>
                <div className='finance-desc'>
                    <Typography>
                        Using the latest advancements in dental tech, results are visible in early stages.
                    </Typography>
                    <Typography>
                        Call today for a personalised quote.
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default Home;