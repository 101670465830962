import { useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import './InfoCard.css';

interface InfoCardProps {
    width?: string | number;
    height?: string | number;
    children: ReactNode;
}

function InfoCard(props: InfoCardProps) {
    const theme = useTheme();

    return (
        <div className='info-card' style={{
            width: props.width || undefined, 
            height: props.height || undefined,
            backgroundColor: theme.palette.primary.main
        }}>
            {props.children}
        </div>
    )
};

export default InfoCard;